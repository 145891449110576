.pagination {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-bottom: env(safe-area-inset-bottom);
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }
  .pagination * {
    -webkit-box-sizing: border-box;
            box-sizing: border-box; }
  .pagination__button {
    background: transparent;
    border-radius: 0;
    border: none;
    height: 50px;
    width: 50px;
    -webkit-transition: background 0.5s;
    -o-transition: background 0.5s;
    transition: background 0.5s;
    color: rgba(255, 255, 255, 0.6);
    text-align: center; }
    .pagination__button:focus {
      color: rgba(255, 255, 255, 0.9);
      outline: 2px solid rgba(255, 255, 255, 0.9);
      outline-offset: -4px; }
    .pagination__button:active {
      background: rgba(0, 0, 0, 0.4); }
