.layout {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  height: 100%;
  background: #2d3135;
  max-width: 100%; }
  .layout__header {
    max-width: 1280px;
    width: 100%;
    -ms-flex-preferred-size: auto;
        flex-basis: auto; }
  .layout__main {
    -webkit-box-flex: 1;
        -ms-flex: 1 auto;
            flex: 1 auto;
    max-width: 1280px;
    width: 100%;
    -ms-flex-preferred-size: auto;
        flex-basis: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex; }
  .layout__footer {
    max-width: 1280px;
    width: 100%;
    -ms-flex-preferred-size: auto;
        flex-basis: auto; }
