.close-icon {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 32px;
  line-height: 18px;
  color: #fff;
  cursor: pointer; }
  @media (min-width: 320px) and (max-width: 480px) {
    .close-icon {
      top: 16px; } }
