.pmc-title {
  text-align: center;
  font-size: 2em;
  font-family: Times, serif;
  color: #fff;
  line-height: 2.4em;
  letter-spacing: 0.5px;
  margin-right: 40px;
  margin-left: 10px; }
  @media (min-width: 320px) and (max-width: 480px) {
    .pmc-title {
      font-size: 1.2em;
      line-height: 50px;
      white-space: nowrap;
      -o-text-overflow: ellipsis;
         text-overflow: ellipsis;
      min-height: 50px; } }
