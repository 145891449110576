.pmc-viewer-component__viewer {
  z-index: 1000;
  opacity: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  -webkit-transition: opacity 0.3s;
  -o-transition: opacity 0.3s;
  transition: opacity 0.3s; }
  .pmc-viewer-component__viewer .viewer {
    -webkit-transform: scale(0.95);
        -ms-transform: scale(0.95);
            transform: scale(0.95);
    -webkit-transition: -webkit-transform 0.3s;
    transition: -webkit-transform 0.3s;
    -o-transition: transform 0.3s;
    transition: transform 0.3s;
    transition: transform 0.3s, -webkit-transform 0.3s; }
  .pmc-viewer-component__viewer .layout {
    background: rgba(45, 49, 53, 0.95); }
  .pmc-viewer-component__viewer .layout__footer {
    -webkit-transform: translateY(100px);
        -ms-transform: translateY(100px);
            transform: translateY(100px);
    -webkit-transition: -webkit-transform 0.3s;
    transition: -webkit-transform 0.3s;
    -o-transition: transform 0.3s;
    transition: transform 0.3s;
    transition: transform 0.3s, -webkit-transform 0.3s; }
  .pmc-viewer-component__viewer--isOpen {
    pointer-events: visible;
    opacity: 1; }
    .pmc-viewer-component__viewer--isOpen .viewer {
      -webkit-transform: scale(1);
          -ms-transform: scale(1);
              transform: scale(1); }
    .pmc-viewer-component__viewer--isOpen .layout__footer {
      -webkit-transform: translateY(0);
          -ms-transform: translateY(0);
              transform: translateY(0); }
    .pmc-viewer-component__viewer--isOpen .layout__header {
      pointer-events: visible; }
  @media (min-width: 1025px) and (max-width: 1280px) {
    .pmc-viewer-component__viewer .layout__header {
      -webkit-transform: translateY(-100px);
          -ms-transform: translateY(-100px);
              transform: translateY(-100px);
      -webkit-transition: -webkit-transform 0.3s;
      transition: -webkit-transform 0.3s;
      -o-transition: transform 0.3s;
      transition: transform 0.3s;
      transition: transform 0.3s, -webkit-transform 0.3s; }
    .pmc-viewer-component__viewer--isOpen .layout__header {
      pointer-events: visible;
      -webkit-transform: translateY(0);
          -ms-transform: translateY(0);
              transform: translateY(0); } }
