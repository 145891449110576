.pmc-footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  padding: 0; }
  .pmc-footer__slider {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1; }
