.viewer {
  background: transparent;
  position: relative;
  height: auto;
  width: 100%; }
  .viewer .openseadragon-canvas:focus {
    outline: none; }
  .viewer--loading {
    cursor: wait;
    pointer-events: none; }
  .viewer--loading * {
    cursor: wait;
    pointer-events: none; }
  .viewer--loading:after {
    content: 'loading...';
    color: #fff;
    padding: 20px;
    background: rgba(0, 0, 0, 0.6);
    position: absolute;
    top: 40%;
    left: 50%;
    width: 200px;
    margin-left: -100px;
    text-align: center; }
  .viewer--error:after {
    content: "Something went wrong, we can't display this content at the moment";
    color: #fff;
    padding: 20px;
    background: rgba(140, 0, 0, 0.6);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 20; }
  .viewer__controls {
    z-index: 19;
    position: absolute;
    top: 5px;
    right: 5px;
    opacity: 0;
    pointer-events: none; }
    .viewer__controls--active {
      pointer-events: initial;
      opacity: 1; }
  .viewer__control {
    background: rgba(0, 0, 0, 0.7);
    border: none;
    height: 50px;
    width: 50px;
    border-radius: 0;
    -webkit-transition: background 0.5s;
    -o-transition: background 0.5s;
    transition: background 0.5s;
    color: #fff;
    text-align: center; }
    @media (max-width: 40em) {
      .viewer__control {
        height: 40px;
        width: 40px; } }
    .viewer__control:focus {
      outline: 2px solid #fff;
      outline-offset: -3px; }
    .viewer__control:active {
      background: #111; }
  .viewer__image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 1; }
    .viewer__image img {
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      pointer-events: none;
      max-height: 100%;
      max-width: 100%;
      width: auto;
      height: auto;
      left: 0;
      right: 0; }
    .viewer__image--disabled {
      opacity: 0;
      pointer-events: none; }
  .viewer__osd {
    background: #222;
    -webkit-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0; }
    .viewer__osd--active {
      opacity: 1;
      pointer-events: initial; }

.annotation {
  outline: 1px solid #ff7855;
  background: rgba(255, 120, 85, 0.2);
  cursor: pointer;
  pointer-events: visible; }
  .annotation--search {
    cursor: initial;
    background: rgba(255, 120, 85, 0.3);
    outline: 1px solid #ff7855; }
  .annotation--external {
    cursor: initial;
    background: rgba(255, 255, 255, 0.3);
    outline: 1px solid skyblue; }
