.viewer-controls {
  z-index: 19;
  margin: 4px 10px;
  opacity: 0;
  pointer-events: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }
  .viewer-controls--active {
    pointer-events: initial;
    opacity: 1; }
  .viewer-controls__control {
    background: transparent;
    border: none;
    height: 36px;
    width: 36px;
    font-size: 14px;
    border-radius: 0;
    -webkit-transition: background 0.5s;
    -o-transition: background 0.5s;
    transition: background 0.5s;
    color: rgba(255, 255, 255, 0.6);
    text-align: center;
    padding: 7px; }
    @media (min-width: 320px) and (max-width: 480px) {
      .viewer-controls__control {
        width: 30px; } }
    .viewer-controls__control:focus {
      color: rgba(255, 255, 255, 0.9);
      outline: 2px solid rgba(255, 255, 255, 0.9);
      outline-offset: -3px; }
    .viewer-controls__control:active {
      background: #111; }
    .viewer-controls__control svg {
      display: inline-block;
      margin: auto; }
