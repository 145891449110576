.control-bar {
  border-top: 1px solid #fff;
  padding: 0 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row; }
  @media (min-width: 320px) and (max-width: 480px) {
    .control-bar {
      padding: 0 10px; } }
  .control-bar__left {
    padding: 4px 0;
    width: 50%; }
  .control-bar__right {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end; }
